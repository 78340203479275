.author-link {
    display: block;
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.display-name {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}