.container {
    font-size: 1rem;
}
.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
.coin {
  max-width: 25px;
}

.fl-item {
  display: block;
}

.header_avatar .price p {
    max-width: 200px;
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
}
.is_dark .logo-footer_light,
.logo-footer_dark {
  display: block;
}
.is_dark .logo-footer_dark,
.logo-footer_light {
  display: none;
}
.author-link {
    display: block;
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.display-name {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.h-price {
    max-width: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.span-price {
    max-width: 70px;   
}

.type-token {
    margin-left: 5px;
}
.upload__image-wrapper {
  display: flex;
  justify-content: center;
}

.btn-edit {
  position: absolute;
  z-index: 1;
  padding: 5px 9px;
  top: 0;
  right: 0px;
  margin: 5px;
}
.upload-preview {
  width: 237px;
}

.image-item__btn-wrapper {
  justify-content: space-around;
  display: flex;
  margin-top: 1rem;
  -webkit-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}

.upload__image-wrapper button,
.image-item__btn-wrapper button {
  padding: 8px 20px;
  color: #fff;
  background: transparent none repeat scroll 0 0;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.7px;
  border: 1.5px solid #fff;
}

.feature-profile .user-avatar {
  font-size: 10rem;
}
.selling-bid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}
.quantity-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.currency {
  font-size: 1.8rem;
  padding-left: 10px;
}
.selling-bid .btn {
  color: white;
}
.quantity-wrapper .quantity {
  background-color: transparent;
  background-color: initial;
}

.place-bid__complete {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.check-mark {
  width: 20rem;
}

.input-price {
  background-color: #1f1f28 !important;
  color: #fff !important;
}

.spinner {
    box-sizing: border-box;
}

.loader{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 2px solid white;
    border-bottom-color: transparent;
    position: relative;
    animation: spinner 1s linear infinite paused;
    -webkit-animation: spinner 1s linear infinite;
    margin-right: 10px;
  }
  
  @-webkit-keyframes spinner {
    to {
      transform: rotate(1turn);
      -webkit-transform: rotate(1turn);
      -moz-transform: rotate(1turn);
      -ms-transform: rotate(1turn);
      -o-transform: rotate(1turn);
    }
  }
  
  @keyframes spinner {
    to {
      transform: rotate(1turn);
      -webkit-transform: rotate(1turn);
      -moz-transform: rotate(1turn);
      -ms-transform: rotate(1turn);
      -o-transform: rotate(1turn);
    }
  }
.check {
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 20px;
  width: 15px;
  border-bottom: 7px solid #78b13f;
  border-right: 7px solid #78b13f;
  margin-right: 10px;
  margin-bottom: 5px;
}
.fail {
  display: inline-block;
  position: relative;
  border: 1px solid red;
  border-radius: 50%;
  font-size: 0.4px;
  width: 50em;
  height: 50em;
  color: red;
  margin-right: 10px;
}

.fail::before,
.fail::after {
  content: '';
  width: 5em;
  height: 34em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -17em;
  margin-left: -2em;
  background-color: currentColor;
  border-radius: 3px;
}
  
.fail::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
  
.fail::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.place-bid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
}
.place-bid .btn {
  color: white;
}
.quantity-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.currency {
  font-size: 1.8rem;
  padding-left: 10px;
}
.quantity-wrapper .quantity {
  background-color: transparent;
  background-color: initial;
}

.place-bid__complete {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.check-mark {
  width: 20rem;
}

.fl-item {
  display: block;
}
.is_dark .dropdown-item {
  color: #fff;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: transparent;
  background-color: initial;
  color: #fff;
}
.fl-item {
  display: block;
}

.client-infor .meta-info {
  overflow: hidden;
  text-overflow: ellipsis;
}

.history-infor {
  font-size: 13px;
  line-height: 20px;
}
.history-infor strong {
  max-width: 15rem;
  overflow: hidden;
  line-height: 1rem;
  display: inline-block;
  text-overflow: ellipsis;
}

.fl-item {
  display: block;
}
