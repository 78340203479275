.fl-item {
  display: block;
}

.client-infor .meta-info {
  overflow: hidden;
  text-overflow: ellipsis;
}

.history-infor {
  font-size: 13px;
  line-height: 20px;
}
.history-infor strong {
  max-width: 15rem;
  overflow: hidden;
  line-height: 1rem;
  display: inline-block;
  text-overflow: ellipsis;
}
