.fl-item {
  display: block;
}

.header_avatar .price p {
    max-width: 200px;
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
}